<template>
  <v-app class="layout-bg">
    <v-main>
      <router-view></router-view>
    </v-main>
    <v-footer class="grey lighten-3 py-4">
      <v-spacer></v-spacer>
      <div>&copy; {{ app_name }} {{ new Date().getFullYear() }}</div>
      <v-spacer></v-spacer>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  name: "public",
  data() {
    return {
      app_name: process.env.VUE_APP_NAME,
    };
  },
};
</script>

<style scoped>
.layout-bg {
  background: #eff3ff;
}
</style>
